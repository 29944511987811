import React ,{useEffect,useState}from "react";
import "./Video.css";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { useRef } from "react";
import axios from "axios";
import { Toast } from "../Deshboard/Notification";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";

const Video = () => {
  const urlRef = useRef();
  const [videoData,setVideoData]=useState([])
  const [reload,setReload]=useState(false)

const baseUrl=process.env.REACT_APP_API_BASEURL

  const hendelFolder = (e) => {
    e.preventDefault();
    const url = urlRef.current.value;
  
    axios
      .post(
        `${baseUrl}/video/added`,
        { url },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        urlRef.current.value = "";
        setReload(reload=>!reload)
        Toast.fire({
          icon: "success",
          title: "successfully Video added",
        });
      })
      .catch((err) => {

         console.log(err)
    
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `<p  href="">Please try again!!!!</p>`,
        });
      });





  };
// get all data 
   useEffect(() => {

      axios.get(`${baseUrl}/video/all`)
       .then(res=>{setVideoData(res.data)})
   }, [reload])
  // hendel delete


  const hendelDelete=(id)=>{

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/video/delete/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            setReload(reload=>!reload)
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: `<p  href="">Please try again!!!!</p>`
            })
          });
      }
    });

   

       
  }

  // hendel update Link 
 const handelEdit= async(id)=>{

  const { value: url } = await Swal.fire({
    title: "Put your new link here",
    input: "url",
    inputLabel: "Updated  Video Link ",
    inputPlaceholder: "put your link here",
  });

  if (url) {
    const data = { id,url };

    axios
      .patch(`${baseUrl}/video/update`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {

          setReload(reload=>!reload)
        Toast.fire({
          icon: "success",
          title: "updated new link  successfully",
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `<p  href="">Please try again!!!!</p>`,
        });
      });
  }


      
 }
     
  return (
    <>
      <PanelHeader
        size="sm"
        content={
          <div className="header text-center">
            <h2 className="title">Video Setting </h2>
          </div>
        }
      />
      <div className="content">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card shadow p-4">
              <label className="d-block">Put your video Link Here</label> <br />
              <form onSubmit={hendelFolder}>
                <div class="input-group mb-3">
                  <input
                    type="url"
                    class="form-control"
                    placeholder="put your video link here"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    ref={urlRef}
                    required
                  />
                  <button
                    class="btn btn-success"
                    type="submit"
                    id="button-addon2"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-8 offset-md-2 card p-4 shadow my-3">
        <h3 className="text-center text-success"> Video List  </h3>

        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-heading-data">
              <th>Serial</th>
              <th>Video Link</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="folder-list-body">
            {videoData.map((x, index) => {
              return (
                <tr key={x.id}>
                  <td>{index + 1}</td>
                  <td >{x.url}</td>
                  <td>
                    {" "}
                    <button onClick={() => handelEdit(x.id)} className="btn">
                      <FiEdit className="seriesFolderEditeIcon" />
                    </button>
                    <button onClick={() => hendelDelete(x.id)} className="btn">
                      <MdDeleteForever className="deleteIcon" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
        </div>

      </div>
    </>
  );
};

export default Video;
