import axios from "axios";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { baseUrl } from "../../../../App";
import { Toast } from "../../Deshboard/Notification";

const SeriseFolderCreate = ({setReload}) => {
  const FolderRef = useRef("");


  const hendelFolder = (e) => {
    e.preventDefault();
    const folderName = FolderRef.current.value;

    axios
      .post(`${baseUrl}/series/addfolder`, {folderName}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setReload((reload)=>!reload)
        FolderRef.current.value = "";
        Toast.fire({
            icon: "success",
            title: "Folder create  successfully",
          });
      })     
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: `<p  href="">Please try again!!!!</p>`
        })
      });

    
  };
  return (
    <div className="card shadow p-4">
      <form onSubmit={hendelFolder}>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Enter Folder Name"
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
            ref={FolderRef}
            required
          />
          <button class="btn btn-success" type="submit" id="button-addon2">
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default SeriseFolderCreate;
