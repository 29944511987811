import React from "react";
import "video-react/dist/video-react.css";
import "./NewsEvent.css";
import Layout from "../Layout/Layout";
import { useEffect } from "react";
import { useState } from "react";
import LoadingSeaner from "../LoadingSeaner/LoadingSeaner";
import { Player } from "video-react";
import Vimeo from "@u-wave/react-vimeo";
import axios from "axios";
import ReactPlayer from "react-player";


import { Suspense } from "react";
import { baseUrl } from "../../App";

const VideoPlayer = React.lazy(() => import("./VideoPlayer"));



const NewsEventVideo = () => {
  const [loading, setLoading] = useState(true);
  const [video, setVideo] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  //  get video data

  useEffect(() => {
    axios
      .get(`${baseUrl}/video/all`)
      .then((res) => setVideo(res.data));
  }, []);

  if (video.length < 1 && loading) {
    return (
      <Layout>
        <LoadingSeaner />
      </Layout>
    );
  } else {
    return (
      <Layout>
        <div className="container mt-2 ">
          <h3 className="text-center text-Capitalize p-5 project-title ">
            News & Event Video Gallery
          </h3>
          <div className="row">
            {video.map((x) => {
              return (
                <div className="col-xl-6 col-lg-6 col-md-6 ">
                  <div className="VideoContainer p-2">
                  <Suspense
                fallback={
                  <>
                   
                    
                    <LoadingSeaner />
                  
                  </>
                }
              >
                  <VideoPlayer url={x.url} /> 
              </Suspense>
                  
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    );
  }
};

export default NewsEventVideo;
