import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { useParams } from 'react-router';
import Swal from 'sweetalert2';
import { baseUrl } from '../../../../App';
import PanelHeader from '../../../components/PanelHeader/PanelHeader';
 import "./SeriesImage.css"
const SeriesImage = () => {
 const [reload,setImgReload]=useState(false)
     const [image, setImage]=useState([])

     const {id}=useParams()




        
   useEffect(() => {

      axios.get(`${baseUrl}/series/getFolderData/${id}`)
      .then(res=>{

          setImage(res.data)
      })
      .catch(err=>{

          console.log(err.message)
      })
      
    
   }, [reload])

//    hendel image update 
   const hendelUpdate=async(data)=>{ 
  const id=data.id;
    let imgUrl=data.img

    
    let formData = new FormData();
    // find previous image name
    const  index = imgUrl.lastIndexOf("/") + 1;
    const  imageName = imgUrl.substr(index);

    // input images by popup
    const { value: file } = await Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload your profile picture",
      },
    });

    if (file?.size > 1000000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "File Size too large ",
        footer: "<p> Maximum File Size Allow 1MB</p>",
      });
    } else {
      formData.append("image", file);
      formData.append("id", id);
      formData.append("PreImgUrl", imageName)
      axios
        .patch(`${baseUrl}/series/image/update`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const reader = new FileReader();
            reader.onload = (e) => {
              Swal.fire({
                title: "Your uploaded picture",
                imageUrl: e.target.result,
                imageAlt: "The uploaded picture",
              });
            };
            reader.readAsDataURL(file);

            setImgReload((imgReload) => !imgReload);
          }
        })
        .catch((err) => {

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Uploaded failed",
            footer: "<p> Server error Try again later!!</p>",
          });
        });
    }
       


     
   }

//    hendal Delete images

 const hendelDelete=(data)=>{
    const id=data.id;
    let  image=data.img

       // find image 
       const  index = image.lastIndexOf("/") + 1;
       const  imageName = image.substr(index);
     
     
       
       Swal.fire({
         title: "Are you sure?",
         text: "You won't be able to revert this!",
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Yes, delete it!",
       }).then((result) => {
         if (result.isConfirmed) {
           axios
             .delete(`${baseUrl}/series/image/delete/${id}/${imageName}`, {
               headers: {
                 Authorization: `Bearer ${localStorage.getItem("token")}`,
               },
             })
             .then((result) => {
               Swal.fire("Deleted!", "Your file has been deleted.", "success");
               setImgReload((imgReload) => !imgReload);
             })
             .catch((err) => {
               Swal.fire({
                 icon: 'error',
                 title: 'Oops...',
                 text: 'Something went wrong!',
                 footer: `<p  href="">Please try again!!!!</p>`
               })
             });
         }
       });
       
 }

    return (
        <>
        <PanelHeader
          size="sm"
          content={
            <div className="header text-center">
              <h2 className="title">Series Image setting </h2>
            </div>
          }
        />
        <div className="content">
          <div className="row">

            {image.map(x=>  <div className="col-md-2 col-4 mt-5"> 

             <div className="imageContainer card ">

                    <img src={x.img} alt="" />  

                     <button onClick={()=>hendelDelete(x)} className="btn btn-danger">Delete</button>     
                      <button onClick={()=>hendelUpdate(x)} className="btn btn-primary mt-2">Edit</button>           
             </div>

                   
            </div> )
            
                 
            }

          
          </div>
        </div>
      </>
    );
};

export default SeriesImage;