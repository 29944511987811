import React, { useContext } from "react";
import { baseUrl, userContext } from "../../App";
import Layout from "../Layout/Layout";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styles from "./Home.module.css";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { CustomGallery, DefaultLayout } from "react-photoswipe-gallery";
import SingleProject from "../Projects/SingleProject/SingleProject";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import { useRef } from "react";

import LoadingSeaner from "../LoadingSeaner/LoadingSeaner"

const Home = () => {
  const { personalInfo } = useContext(userContext);




   const layoutRef=useRef()

  const [ImageData,setImageData]=useState([])


  useEffect(() => {
    axios
      .get(`${baseUrl}/projects/data/get/home`)
      .then((data) => {
        setImageData(data.data);
      })
      .catch((err) => {
        console.log(err);
      });

  
  }, []);


  return (
    <>
      <Layout>
        <div className="container mt-5" >
        <div className="row  d-flex justify-content-around">
         <div className="col-md-12  d-flex flex-wrap justify-content-center">
            
        <CustomGallery layoutRef={layoutRef} ui={PhotoSwipeUI_Default}>
           
             
           {ImageData.length > 0 ? (
             
             ImageData.map((x) => (
               
               <SingleProject project={x} key={x.id} />
             ))) :  <LoadingSeaner/>
            
           }
                      
      
        
       </CustomGallery>
       <DefaultLayout
         shareButton={false}
         zoomButton={false}
         ref={layoutRef}
       />
          </div>
        </div>
        </div>
      </Layout>

    
    </>
  );
};

export default Home;
