import React from "react";
import { useContext } from "react";
import { ebookContext } from "./E_Book";
import draftToHtml from "draftjs-to-html";
import styles from "./E_Book.module.css";

import ProjectContainer from "../Projects/ProjectContainer/ProjectContainer";

const Details = ({ books,seriseImageData }) => {
  const { series, toggle, setToggle } = useContext(ebookContext);

 


  return (
    <>
      <div className={`${styles.main}`}>
        <button
          className={`${styles.openbtn} btn  mb-5 shadow-lg`}
          onClick={() => setToggle(!toggle)}
        >
          ☰ {series? "Open Art Work  List ": " Open Book List"} 
        </button>
        <div className="p-4">
          {
            !series?  books.map((x) => (
              <>
                <div key={x.id} className={`${styles.bookContainer} my-5`}>
                  <h1 className={`${styles.bookName} text-center`}>{x.topic}</h1>
                  <div 
                    dangerouslySetInnerHTML={{
                      __html: draftToHtml(JSON.parse(x.description)),
                    }}   
                  ></div>
                <hr className={styles.hrEnd} />
                   
                </div>
              </>
            )) : <ProjectContainer 
            
            title={false}
            projectImg={seriseImageData}
            projectDetails=""
            
             />

            }
        </div>
      </div>
    </>
  );
};

export default Details;
