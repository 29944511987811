import React from "react";
import "./NewsEvent.css";
import Layout from "../Layout/Layout";
import { useState } from "react";
import { useEffect } from "react";
import LoadingSeaner from "../LoadingSeaner/LoadingSeaner";
import ReactImageZoom from 'react-image-zoom';
const NewsEventImage = ({ projectImg }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (projectImg.length < 1 && loading) {
    return (
      <Layout>
        <LoadingSeaner />
      </Layout>
    );
  } else {
    return (
      <Layout>
        <div className="container mt-2 ">
        <h3 className="text-center text-Capitalize p-5 project-title ">
                 News & Event Gallery
                </h3>
          
          <div className="d-flex justify-content-center flex-wrap ">
            {projectImg.map((x) => {
              return (
            
                  <div className="newsImageContainer m-3 p-2">
                    {/* <img src={x.img} alt="" /> */}
                    <ReactImageZoom className="img-thumbnail" zoomPosition={"original"} width={400}  img={x.img}/>
                  </div>
             
              );
            })}
               </div>
          </div>
      
      </Layout>
    );
  }
};

export default NewsEventImage;
