import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Container, Nav, Navbar } from "react-bootstrap";
import styles from "./Navigation.module.css";
import { BiDotsHorizontal } from "react-icons/bi";
import { BiAlignLeft } from "react-icons/bi";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { userContext } from "../../App";
import SocialLink from "../SocialLink/SocialLink";
const Navigation = ({fixed}) => {

  const { personalInfo } = useContext(userContext);
  const [toggleButton, settoggleButton] = useState(true);
  const isActive = {

    borderLeft:"2px solid white",
    borderBottom:"4px solid white",
    color:"white"

    
  };

   

  return (
    <Navbar expand="lg"
    
    fixed={fixed && "top"}

    
   >
      <Container>
        <Navbar.Brand href="/home">
      
          <h3 className={styles.logoHeading}>{personalInfo?.name}</h3>
     <h6 className={styles.title}>Visual artist  </h6>
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => settoggleButton(!toggleButton)}>
          {toggleButton ? (
            <span className={styles.show_icon}>
              <BiAlignLeft />
            </span>
          ) : (
            <span className={styles.show_icon}>
              
              <BiDotsHorizontal />
            </span>
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link exact activeStyle={isActive} as={NavLink}   to="/">
              Home
            </Nav.Link>
  
            <Nav.Link activeStyle={isActive} as={NavLink}  to="/artWork">
            Art Work
            </Nav.Link>

            <NavDropdown title="News & Event" id="basic-nav-dropdown">
              <NavDropdown.Item activeStyle={isActive} as={NavLink}  to="/news-event-image-gallery">
              Images Gallery 
              </NavDropdown.Item>
              <NavDropdown.Item activeStyle={isActive} as={NavLink}  to="/news-event-video-gallery">
              Video Gallery
              </NavDropdown.Item>
             
             
            </NavDropdown>
  
            <Nav.Link activeStyle={isActive} as={NavLink}  to="/gallery">
            Gallery 
            </Nav.Link>
           
            <Nav.Link activeStyle={isActive} as={NavLink}  to="/profile">
              Profile
            </Nav.Link>
            <Nav.Link activeStyle={isActive} as={NavLink}  to="/contract">
              Contact
            </Nav.Link>
          </Nav>
          <SocialLink/>
        </Navbar.Collapse>
      </Container>
     
    </Navbar>
  );
};

export default Navigation;
