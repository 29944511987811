import "bootstrap/dist/css/bootstrap.css";
import "./adminDeshbord/assets/css/demo.css";
import "./adminDeshbord/assets/scss/now-ui-dashboard.scss";
import "./App.css";
import "./resource/GlobalStyle/globalColor.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import ProjectContainer from "./components/Projects/ProjectContainer/ProjectContainer";
import { useEffect, useState } from "react";
import Contract from "./components/Contract/Contract";
import About from "./components/About/About";
import AdminLayout from "./adminDeshbord/layouts/Admin";
import LogIn from "./adminDeshbord/LogIn/LogIn";
import { createContext } from "react";
import PrivateRoute from "./adminDeshbord/LogIn/PrivateRoute";
import axios from "axios";
import E_Book from "./components/E_Book/E_Book";
import Layout from "./components/Layout/Layout";
import NewsEventImage from "./components/NewEvent/NewsEventImage";
import NewsEventVideo from "./components/NewEvent/NewsEventVideo";

export const userContext = createContext();
export const baseUrl=process.env.REACT_APP_API_BASEURL

function App() {
  const [projectImg, setProjectImg] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [loginUser, setLoginUser] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});

   let url=process.env.REACT_APP_API_BASEURL
  // Filter function for filter project images data and details

 

  const FilterProject = (data, fileName) => {
    return data.filter((x) => x.fileName === fileName);
  };

  // get projectData from database
  useEffect(() => {
    axios.get(`${url}/projects/data/get`).then((result) => {
      setProjectImg(result.data);
    });

    axios
      .get(`${url}/projects/details/data/getDetails`)
      .then((result) => {
        setProjectDetails(result.data);
      });
    axios
      .get(`${url}/adminInformation/api/contractInformation/get`)
      .then((result) => {
        setPersonalInfo(result.data[0]);
      });
  }, []);


  return (
    <userContext.Provider value={{ loginUser, setLoginUser, personalInfo }}>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />

          <Route exact path="/artWork">
            <E_Book series={true} />
          </Route>

          <Route exact path="/news-event-image-gallery">
            <NewsEventImage
              projectImg={FilterProject(projectImg, "newsEvent")}
            />
          </Route>

          <Route exact path="/news-event-video-gallery">
            <NewsEventVideo />
          </Route>

          <Route exact path="/gallery">
            <Layout>
              <ProjectContainer
                title="Gallery"
                projectImg={FilterProject(projectImg, "gallery")}
                projectDetails={FilterProject(projectDetails, "gallery")}
              />
            </Layout>
          </Route>

          <Route exact path="/profile">
            <About />
          </Route>

          <Route exact path="/contract">
            <Contract />
          </Route>
          <Route exact path="/login">
            <LogIn />
          </Route>

          <PrivateRoute path="/admin">
            <Route render={(props) => <AdminLayout {...props} />} />
          </PrivateRoute>
        </Switch>
      </Router>
    </userContext.Provider>
  );
}

export default App;
